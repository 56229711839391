import { localAxiosClient } from "@/common/utils/axiosClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addErrorDetails } from "./utils";

/**
 * Wrapper for useMutation hooks to send +delete+ requests to an api endpoint
 *
 * If the mutation is successful and an +cacheKey+ is provided, the cache will
 * be optimistically updated (deleted).
 *
 * @param   {string}  url  The API endpoint to mutate against
 * @param   {string}  key  (optional) The cacheKey to update on mutation success.
 *
 * @return  {object}            @see https://react-query.tanstack.com/guides/mutations
 */
const useDelete = ({ url, key, options = {} }) => {
  const queryClient = useQueryClient();

  const doMutation = async () => {
    try {
      const result = await localAxiosClient.delete(url);
      return {
        data: result.data,
        status: result.status,
        errors: result.data?.errors,
      };
    } catch (error) {
      throw addErrorDetails(error);
    }
  };

  const onSuccess = (object) => {
    // If a key was provided, remove any queries against the key
    if (object.status === 200 && !!key) {
      queryClient.removeQueries({ queryKey: key, exact: true });
    }

    if (options.onSuccess) options.onSuccess(object);
  };

  /**
   * Wrap the React-Query useMutation hook and return it
   */
  return useMutation({
    mutationFn: doMutation,
    ...options,
    onSuccess,
  });
};

export default useDelete;
