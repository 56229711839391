import React from "react";

import Container from "@/common/components/elements/Container";
import FlexBox from "@/common/components/elements/FlexBox";
import { Typography } from "@mui/material";

const BaseErrorHero = ({ hero, description }) => {
  return (
    <Container maxWidth="md">
      <FlexBox flexDirection="column" alignItems="center" justifyContent="center" sx={{ mt: 10 }}>
        <Typography variant="h1" sx={{ fontSize: "5rem" }} align="center">
          {hero}
        </Typography>
        <Typography variant="body1" align="center">
          {description}
        </Typography>
      </FlexBox>
    </Container>
  );
};

export default BaseErrorHero;
