import { ConfirmProvider } from "material-ui-confirm";

const ConfirmationProvider = ({ children }) => {
  return (
    <ConfirmProvider defaultOptions={{ confirmationButtonProps: { autoFocus: true } }}>
      {children}
    </ConfirmProvider>
  );
};

export default ConfirmationProvider;
