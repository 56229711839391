import mergeSx from "@/common/utils/mergeSx";
import { Avatar } from "@mui/material";

const Identicon = ({ seed, size, sx, ...restProps }) => {
  if (!seed) return null;

  // Encode seed for api call
  const punctuation = /[\.,?!]/g;
  const safeSeed = encodeURIComponent(seed.replace(punctuation, ""));
  const src = `https://api.dicebear.com/6.x/identicon/svg?seed=${safeSeed}&scale=80`;

  // Merge sx with size property
  const sizeSx = size ? { width: size, height: size } : {};
  const avatarSx = mergeSx(sizeSx, sx);

  return <Avatar src={src} sx={avatarSx} {...restProps} />;
};

export default Identicon;
