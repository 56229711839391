import { useState } from "react";

import reactQueryClient from "@/common/utils/reactQueryClient";
import { HydrationBoundary, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const ReactQueryClientProvider = ({ dehydratedState, children }) => {
  const [queryClient] = useState(reactQueryClient);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </HydrationBoundary>
    </QueryClientProvider>
  );
};

export default ReactQueryClientProvider;
