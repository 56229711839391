import Head from "next/head";

import AppBar from "./AppBar";
import BetaAlertOutlet from "./BetaAlertOutlet";

const AppLayout = ({ children }) => {
  return (
    <>
      <Head>
        <title>DWBeyond</title>
      </Head>
      <AppBar />
      <BetaAlertOutlet>{children}</BetaAlertOutlet>
    </>
  );
};

export default AppLayout;
