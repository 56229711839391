import { remoteAxiosClient } from "@/common/utils/axiosClient";

const buildPrefetch = ({ queryClient, url, key, options = {} }) => {
  const { bearerToken } = options;

  const axiosConfig = {};
  if (!!bearerToken) axiosConfig.headers = { Authorization: `Bearer ${bearerToken}` };

  const doPrefetch = async () => {
    try {
      const { data } = await remoteAxiosClient.get(url, axiosConfig);
      queryClient.setQueryData(key, data);
      return { errorCode: null, data };
    } catch (error) {
      return { errorCode: error.response?.status || 500 };
    }
  };

  return doPrefetch;
};

export default buildPrefetch;
