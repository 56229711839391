import { QueryClient } from "@tanstack/react-query";

const defaultOptions = {
  queries: {
    retry: (failureCount, error) => {
      // Don't retry :unauthorized, :unauthenticated, or :not_found responses
      if ([404, 401, 403].includes(error.statusCode)) return false;
      return failureCount < 2;
    },
  },
};

const reactQueryClient = new QueryClient({ defaultOptions });
export default reactQueryClient;
