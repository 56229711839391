import { localAxiosClient } from "@/common/utils/axiosClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addErrorDetails } from "./utils";

/**
 * Wrapper for useMutation hooks to send +update+ requests to an api endpoint
 *
 * If the mutation is successful and an +cacheKey+ is provided, the cache will
 * be optimistically updated.
 *
 * @param   {string}  url  The API endpoint to mutate against
 * @param   {string}  key  (optional) The cache key to update on mutation success.
 *
 * @return  {object}            @see https://react-query.tanstack.com/guides/mutations
 */
const useUpdate = ({ url, key, options = {} }) => {
  const queryClient = useQueryClient();

  const doMutation = async (payload) => {
    try {
      const result = await localAxiosClient.put(url, payload);
      return {
        data: result.data,
        status: result.status,
        errors: result.data?.errors,
      };
    } catch (error) {
      throw addErrorDetails(error);
    }
  };

  const onSuccess = (object) => {
    // If a key was provided, update that key's cached data
    if (object.status === 200 && !!key) {
      queryClient.setQueryData(key, object.data);
    }

    if (options.onSuccess) options.onSuccess(object);
  };

  // console.log("uU options", options, onSuccess);

  /**
   * Wrap the React-Query useMutation hook and return it
   */
  return useMutation({
    mutationFn: doMutation,
    ...options,
    onSuccess,
  });
};

export default useUpdate;
