import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import routeTo from "../utils/routeTo";
import useCampaign from "./useCampaign";

const routes = {
  playersInCampaign: (campaignId) => routeTo(useCampaign.routes.campaign(campaignId), "players"),
  player: (id) => routeTo("players", id),
};

const keys = {
  playersInCampaign: (campaignId) => buildKey(...useCampaign.keys.campaign(campaignId), "players"),
  player: (id) => buildKey("players", id),
};

const actions = {
  listInCampaign: (campaignId, options) =>
    useRest.fetch({
      url: routes.playersInCampaign(campaignId),
      key: keys.playersInCampaign(campaignId),
      options,
    }),

  delete: (id, campaignId = "nan", options) =>
    useRest.delete({
      url: routes.player(id),
      key: keys.playersInCampaign(campaignId),
      options,
    }),
};

const usePlayer = {
  routes,
  keys,
  ...actions,
};

export default usePlayer;
