import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import routeTo from "../utils/routeTo";
import useCharacter from "./useCharacter";

const routes = {
  itemsByCharacter: (characterId) => routeTo(useCharacter.routes.character(characterId), "items"),
  gearForCharacter: (characterId) => routeTo(useCharacter.routes.character(characterId), "gear"),
  item: (id) => routeTo("items", id),
  tradeItem: (itemId) => routeTo("items", itemId, "trades"),
};

const keys = {
  item: (id) => buildKey("items", id),
};

const actions = {
  createInCharacter: (characterId, options) =>
    useRest.create({
      url: routes.itemsByCharacter(characterId),
      options,
    }),

  addGearToCharacter: (characterId, options) =>
    useRest.create({
      url: routes.gearForCharacter(characterId),
      options,
    }),

  trade: (itemId, options) =>
    useRest.create({
      url: routes.tradeItem(itemId),
      options,
    }),

  update: (id, options) =>
    useRest.update({
      url: routes.item(id),
      key: keys.item(id),
      options,
    }),

  delete: (id, options) =>
    useRest.delete({
      url: routes.item(id),
      key: keys.item(id),
      options,
    }),
};

const useCharacterItem = {
  routes,
  keys,
  ...actions,
};

export default useCharacterItem;
