import React from "react";

import MUIAppBar from "@mui/material/AppBar";
import MUIToolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

const DWBAppBar = styled(MUIAppBar)`
  min-height: 2.5rem;
  height: 2.5rem;
  align-items: center;
  // background-color: #0991ba;
  background-color: rgb(55, 90, 127);
`;

const DWBToolbar = styled(MUIToolbar)(({ theme }) => ({
  minHeight: "2.5rem",
  width: "100%",

  [theme.breakpoints.up("lg")]: {
    width: theme.breakpoints.values.lg,
  },
}));

const Toolbar = ({ children }) => {
  return (
    <DWBAppBar position="fixed">
      <DWBToolbar variant="dense">{children}</DWBToolbar>
    </DWBAppBar>
  );
};

export default Toolbar;
