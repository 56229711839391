import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";

const SnackbarProvider = ({ children }) => {
  return (
    <NotistackSnackbarProvider
      maxSnack={30}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      preventDuplicate={true}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
