import useAuth from "./useAuth";
import useCreate from "./useCreate";
import useDelete from "./useDelete";
import useFetch from "./useFetch";
import useUpdate from "./useUpdate";

const useRest = {
  create: useCreate,
  delete: useDelete,
  fetch: useFetch,
  update: useUpdate,
  auth: useAuth,
};

export default useRest;
