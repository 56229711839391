import MUIContainer from "@mui/material/Container";
import { styled } from "@mui/material/styles";

const Container = styled(MUIContainer, {
  shouldForwardProp: (prop) => prop !== "withBookmarks",
})(
  ({ theme, withBookmarks = true }) => `
  margin-top: 3rem;
  padding-top: ${withBookmarks ? "1rem" : "0.25rem"};
  margin-bottom: ${theme.spacing(10)}
`
);

export default Container;
