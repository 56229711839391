import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import routeTo from "../utils/routeTo";
import useCharacter from "./useCharacter";

const routes = {
  bondsInCharacter: (characterId) => routeTo(useCharacter.routes.character(characterId), "bonds"),
  bond: (id) => routeTo("bonds", id),
};

const keys = {
  bondsInCharacter: (characterId) => buildKey(...useCharacter.keys.character(characterId), "bonds"),
  bond: (id) => buildKey("bonds", id),
};

const actions = {
  createInCharacter: (characterId, options) =>
    useRest.create({
      url: routes.bondsInCharacter(characterId),
      key: keys.bondsInCharacter(characterId),
      options,
    }),

  update: (id, options) =>
    useRest.update({
      url: routes.bond(id),
      key: keys.bond(id),
      options,
    }),

  delete: (id, options) =>
    useRest.delete({
      url: routes.bond(id),
      key: keys.bond(id),
      options,
    }),
};

const useBond = {
  routes,
  keys,
  ...actions,
};

export default useBond;
