import { useEffect, useState } from "react";

const useOpenState = (defaultState = false) => {
  const [isOpen, setIsOpen] = useState(defaultState);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleToggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    setIsOpen,
    handleOpen,
    handleClose,
    handleToggle,
  };
};

export default useOpenState;
