import { SessionProvider } from "next-auth/react";

import ConfirmationProvider from "./ConfirmationProvider";
import ReactQueryClientProvider from "./ReactQueryClientProvider";
import SnackbarProvider from "./SnackbarProvider";

const AppProviders = ({ children, session, dehydratedState }) => {
  return (
    <SessionProvider session={session}>
      <ConfirmationProvider>
        <SnackbarProvider>
          <ReactQueryClientProvider dehydratedState={dehydratedState}>
            {children}
          </ReactQueryClientProvider>
        </SnackbarProvider>
      </ConfirmationProvider>
    </SessionProvider>
  );
};

export default AppProviders;
