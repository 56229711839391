import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import buildPrefetch from "../utils/buildPrefetch";
import routeTo from "../utils/routeTo";

const routes = {
  campaigns: () => routeTo("campaigns"),
  campaign: (id) => routeTo("campaigns", id),
};

const keys = {
  campaigns: () => buildKey("campaigns"),
  campaign: (id) => buildKey("campaigns", id),
};

const prefetch = (queryClient) => ({
  list: (options) =>
    buildPrefetch({
      queryClient,
      url: routes.campaigns(),
      key: keys.campaigns(),
      options,
    }),
  fetch: (id, options) =>
    buildPrefetch({
      queryClient,
      url: routes.campaign(id),
      key: keys.campaign(id),
      options,
    }),
});

const actions = {
  list: (options) =>
    useRest.fetch({
      url: routes.campaigns(),
      key: keys.campaigns(),
      options,
    }),

  fetch: (id, options) =>
    useRest.fetch({
      url: routes.campaign(id),
      key: keys.campaign(id),
      options,
    }),

  create: (options) =>
    useRest.create({
      url: routes.campaigns(),
      key: keys.campaigns(),
      options,
    }),

  update: (id, options) =>
    useRest.update({
      url: routes.campaign(id),
      key: keys.campaign(id),
      options,
    }),

  delete: (id, options) =>
    useRest.delete({
      url: routes.campaign(id),
      key: keys.campaigns(),
      options,
    }),
};

/**
 * Assemble export
 */
const useCampaign = {
  routes,
  keys,
  prefetch,
  ...actions,
};
export default useCampaign;
