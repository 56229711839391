const mergeSx = (...sxes) => {
  const finalSx = [];

  sxes.forEach((item) => {
    finalSx.push(...(Array.isArray(item) ? item : [item]));
  });

  return finalSx;
};

export default mergeSx;
