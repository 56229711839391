import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { ErrorBoundary as ReactErrorBoundary, useErrorBoundary } from "react-error-boundary";

import ErrorHero from "@/common/components/molecules/ErrorHero";
import AppLayout from "@/layouts/AppLayout";
import Bugsnag from "@bugsnag/js";
import { CssBaseline } from "@mui/material";

const ErrorFallback = ({ error }) => {
  const router = useRouter();
  const { resetBoundary } = useErrorBoundary();
  const initialLocation = useRef(false);
  const location = router.asPath;

  useEffect(() => {
    Bugsnag.notify(error, (event) => {
      event.severity = "error";
      event.unhandled = true;
    });
  }, [error]);

  useEffect(() => {
    if (!initialLocation.current) {
      initialLocation.current = location;
    }
  }, [location]);

  useEffect(() => {
    if (initialLocation.current && initialLocation.current !== location) {
      resetBoundary();
    }
  }, [location, resetBoundary]);

  return (
    <>
      <CssBaseline />
      <AppLayout>
        <ErrorHero variant={error?.errorPageType} />
      </AppLayout>
    </>
  );
};

const ErrorBoundary = ({ children }) => {
  return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>;
};

export default ErrorBoundary;
