import ForbiddenHero from "./components/ForbiddenHero";
import InternalErrorHero from "./components/InternalErrorHero";
import NotFoundHero from "./components/NotFoundHero";
import NotImplementedHero from "./components/NotImplementedHero";

const ErrorHero = ({ variant }) => {
  switch (variant) {
    case 403:
    case "403":
    case "unauthorized":
    case "forbidden":
      return <ForbiddenHero />;

    case 401:
    case "401":
    case "unauthorized":
    case 404:
    case "404":
    case "not_found":
      return <NotFoundHero />;

    case 501:
    case "501":
    case "not_implemented":
      return <NotImplementedHero />;

    case 500:
    case "500":
    case "internal_error":
    default:
      return <InternalErrorHero />;
  }
};

export default ErrorHero;
