import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import buildPrefetch from "../utils/buildPrefetch";
import routeTo from "../utils/routeTo";
import defaultOptions from "./defaultOptions";

// Endpoints
const routes = {
  spellLists: () => routeTo("codex", "spell_lists"),
  spellList: (id) => routeTo("codex", "spell_lists", id),
};

// Cache Keys
const keys = {
  spellLists: () => buildKey("codex", "spell_lists"),
  spellList: (id) => buildKey("codex", "spell_lists", id),
};

// Prefetches
const prefetch = (queryClient) => ({
  list: (options) =>
    buildPrefetch({
      queryClient,
      url: routes.spellLists(),
      key: keys.spellLists(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    buildPrefetch({
      queryClient,
      url: routes.spellList(id),
      key: keys.spellList(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
});

// Resource actions
const actions = {
  list: (options) =>
    useRest.fetch({
      url: routes.spellLists(),
      key: keys.spellLists(),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),

  fetch: (id, options) =>
    useRest.fetch({
      url: routes.spellList(id),
      key: keys.spellList(id),
      options: {
        ...defaultOptions,
        ...options,
      },
    }),
};

/**
 * Assembel export
 */
const useCodexSpellList = {
  routes,
  keys,
  prefetch,
  ...actions,
};

export default useCodexSpellList;
