import { useState } from "react";

import FlexBox from "@/common/components/elements/FlexBox";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from "@mui/material";

import useBetaAlertState from "./useBetaAlertState";

const BetaAlertOutlet = ({ children }) => {
  const [isSuppressChecked, setIsSuppressChecked] = useState(false);

  const { showModal, handleClose, handleSuppress } = useBetaAlertState();

  const handleClick = () => {
    if (isSuppressChecked) handleSuppress();
    handleClose();
  };

  return (
    <>
      {children}
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="beta-dialog-title"
        aria-describedby="beta-dialog-description"
        fullWidth={false}
        maxWidth="xs"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <Alert severity="warning" id="beta-dialog-title">
            <Typography>
              This site is a Beta preview of the new interface, but it is accessing{" "}
              <strong>LIVE DATA</strong>!!
            </Typography>
          </Alert>
          <Typography sx={{ my: 3 }} id="beta-dialog-description">
            This means any changes made here <strong>will be reflected on the live site!</strong> If
            you delete your character or campaign just to see what happens, it will absolutely
            delete it on the live site!
          </Typography>
          <FlexBox justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSuppressChecked}
                  onChange={() => setIsSuppressChecked(!isSuppressChecked)}
                />
              }
              label="Don't show this message again."
            />
          </FlexBox>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClick}>I understand</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BetaAlertOutlet;
