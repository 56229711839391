import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import HeaderLink from "./HeaderLink";

const feedbackUrl = "https://docs.google.com/forms/d/1M4wll5Qe5paUDDzwcFQmK-lsfwor3BR16CffaI848T8";

const DesktopNav = () => {
  return (
    <List component="nav">
      <ListItem component="div">
        <ListItemText inset>
          <HeaderLink href={"/"}>Campaigns</HeaderLink>
        </ListItemText>
        <ListItemText inset>
          <HeaderLink href={"/codex"}>Codex</HeaderLink>
        </ListItemText>
        <ListItemText inset>
          <HeaderLink href={feedbackUrl}>Feedback</HeaderLink>
        </ListItemText>
        <ListItemText inset>
          <HeaderLink href="/beta" sx={{ color: (theme) => theme.palette.warning.main }}>
            Beta Info
          </HeaderLink>
        </ListItemText>
      </ListItem>
    </List>
  );
};

export default DesktopNav;
