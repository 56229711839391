import axios from "axios";
import urlJoin from "url-join";
const nextHost = process.env.NEXT_PUBLIC_NEXT_HOST;
const dwbHost = process.env.NEXT_PUBLIC_DWB_HOST;

export const localAxiosClient = axios.create({
  baseURL: urlJoin(nextHost, "/api/"),
});

export const remoteAxiosClient = axios.create({
  baseURL: urlJoin(dwbHost, "/api/v2"),
});
