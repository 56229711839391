import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import buildPrefetch from "../utils/buildPrefetch";
import routeTo from "../utils/routeTo";
import useCampaign from "./useCampaign";

const routes = {
  join: (token) => routeTo("campaigns", token, "join"),
  departure: (id) => routeTo(useCampaign.routes.campaign(id), "departure"),
  invite: (id) => routeTo(useCampaign.routes.campaign(id), "invite"),
};

const keys = {
  invite: (token) => buildKey(...useCampaign.routes.campaign(token), "invite"),
};

const prefetch = (queryClient) => ({
  viewInvite: (token, options) =>
    buildPrefetch({
      queryClient,
      url: routes.join(token),
      key: keys.invite(token),
      options,
    }),
});

const actions = {
  // Show campaign invitation (landing page)
  viewInvite: (token, options) =>
    useRest.fetch({
      url: routes.join(token),
      key: keys.invite(token),
      options,
    }),

  // Reset a campaign's invite token
  resetToken: (id, options) =>
    useRest.update({
      url: routes.invite(id),
      key: useCampaign.keys.campaign(id),
      options,
    }),

  // Accept an invitation
  acceptInvite: (token, options) =>
    useRest.update({
      url: routes.join(token),
      options,
    }),

  // Depart a campaign
  depart: (id, options) =>
    useRest.create({
      url: routes.departure(id),
      options,
    }),
};

const useInvite = {
  routes,
  keys,
  prefetch,
  ...actions,
};

export default useInvite;
