import useRest from "../useRest";
import buildKey from "../utils/buildKey";
import routeTo from "../utils/routeTo";
import useCharacter from "./useCharacter";

const routes = {
  statBlock: (characterId) => routeTo(useCharacter.routes.character(characterId), "stat_block"),
};

const keys = {
  statBlock: (characterId) => buildKey(...useCharacter.routes.character(characterId), "stat_block"),
};

const actions = {
  update: (characterId, options) =>
    useRest.update({
      url: routes.statBlock(characterId),
      key: keys.statBlock(characterId),
      options,
    }),
};

const useStatBlock = {
  routes,
  keys,
  ...actions,
};

export default useStatBlock;
