import Head from "next/head";

import ErrorBoundary from "@/_app/components/ErrorBoundary";
import AppProviders from "@/_app/providers";
import muiTheme from "@/_app/styles/muiTheme";
import ErrorHero from "@/common/components/molecules/ErrorHero";
import { startBugsnag } from "@/common/utils/bugsnag";
import AppLayout from "@/layouts/AppLayout";
import { ThemeProvider } from "@emotion/react";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import CssBaseline from "@mui/material/CssBaseline";

startBugsnag();

export default function MyApp({ Component, pageProps }) {
  const { session, dehydratedState, errorCode, ...restPageProps } = pageProps;

  const getLayout = Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AppProviders session={session} dehydratedState={dehydratedState}>
        <AppCacheProvider {...pageProps}>
          <ThemeProvider theme={muiTheme}>
            <ErrorBoundary>
              <CssBaseline enableColorScheme />
              {!errorCode
                ? getLayout(<Component {...restPageProps} />)
                : getLayout(<ErrorHero variant={errorCode} />)}
            </ErrorBoundary>
          </ThemeProvider>
        </AppCacheProvider>
      </AppProviders>
    </>
  );
}
