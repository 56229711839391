import { Roboto } from "next/font/google";

import { createTheme } from "@mui/material/styles";

const roboto = Roboto({ weight: "400", subsets: ["latin"] });

export const colors = {
  darkCharcoal: "#333",
  catch22: "#222",
  blueSteel: "#374C60",
  blueSteelLight: "#63788d",
  blueSteelDark: "#0c2436",
};

const muiTheme = createTheme({
  palette: {
    mode: "dark",
    common: {
      gray: "#999",
    },

    primary: {
      main: "#34abcf",

      contrastText: "#fff",
    },
    secondary: {
      main: "#D64045",
    },

    text: {
      primary: "rgba(204, 204, 204, 1)",
      title: "#ffffff",
    },
    background: {
      default: "#202124",
      paper: "#222326",
    },
  },
  props: {
    MuiAppBar: {
      color: "inherit",
    },
  },

  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: { fontSize: "2.25rem" },
    h2: { fontSize: "1.75rem" },
    h3: { fontSize: "1.5rem" },
    h4: { fontSize: "1.25rem" },
    h5: { fontSize: "1rem" },
    h6: { fontSize: "1rem" },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "20px",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          "& * .card-title": {
            // todo: obsolete?
            color: "#ffffff",
          },
        },
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        variant: "h3",
        color: "#ffffff",
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },

    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: { variant: "h4" },
        secondaryTypographyProps: { variant: "body1" },
      },
      styleOverrides: {
        root: {
          "& > .MuiListItemText-primary": {
            color: "#ffffff",
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiDialog-paper": {
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10))",
          },
        },
      },
    },

    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        BackdropProps: { invisible: false },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {},
          "&.MuiTab-labelIcon": {
            minHeight: "inherit",
          },
          "&.MuiTab-labelIcon .MuiTab-wrapper > *:first-of-type": {
            marginBottom: "inherit",
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        slotProps: {
          inputLabel: { shrink: true },
        },
      },
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          "& strong": {
            color: "rgb(230,230,230)",
          },
        },
      },
    },
  },
});

export default muiTheme;
